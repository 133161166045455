<template>
  <div>
    <panel-alert
      title="新建睡眠监测"
      confirmTitle="绑定"
      isShowInfo
      isShowBottom
      width="650px"
      @onCloseClick="onCloseClick"
      @onConfirmClick="onConfirmClick"
    >
      <template v-slot:content>
        <div class="bind-sleep-machine">
          <div class="bind-sleep-machine-row bind-sleep-machine-row-1">
            <div class="input-item">
              <must-label isMust text="选择设备"></must-label>
              <el-select
                filterable
                :disabled="initSelectedDevice != undefined"
                v-model="selectedDeviceSN"
                :popper-append-to-body="false"
                @change="onSelectedDevice"
                placeholder="请选择"
                v-loadmore="onDeviceLoadMore"
              >
                <el-option
                  v-for="(item, index) in deviceArray"
                  :disabled="item.userStatusDesc != '闲置'"
                  :key="index"
                  :label="item.sn"
                  :value="item.sn"
                >
                  <span
                    :class="
                      item.userStatusDesc != '闲置'
                        ? 'input-item-disable'
                        : 'input-item-enable'
                    "
                    >{{ `${item.sn} ${item.userStatusDesc}` }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div class="input-item">
              <must-label isMust text="选择患者"></must-label>
              <el-select
                filterable
                :disabled="initSelectedPatient != undefined"
                :popper-append-to-body="false"
                v-model="selectedPatientName"
                @change="onSelectedPatient"
                placeholder="请选择"
                v-loadmore="onPatientLoadMore"
              >
                <el-option
                  v-for="(item, index) in patientArray"
                  :disabled="item.sleepSn != null"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                  <div
                    class="input-item"
                    :class="
                      item.sleepSn != null ? 'input-item-disable' : 'input-item-enable'
                    "
                  >
                    <span class="input-item-name">{{ item.name }}</span>
                    <span>{{ item.age }}</span>
                  </div>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="bind-sleep-machine-row bind-sleep-machine-row-2">
            <div class="input-item">
              <must-label text="已选设备"></must-label>
              <p class="bind-sleep-machine-row-check-container-text">
                {{ selectedDeviceSN }}
              </p>
            </div>
            <div class="bind-sleep-machine-row-check-container">
              <must-label text="已选患者"></must-label>
              <p class="bind-sleep-machine-row-check-container-text">
                {{ selectedPatientName }}
              </p>
              <button
                class="bind-sleep-machine-row-check-container-check"
                @click="onCheckUserInfo"
              >
                查看详情
              </button>
            </div>
          </div>
          <div class="bind-sleep-machine-time-select-container">
            <must-label text="监测时间"></must-label>
            <div class="bind-sleep-machine-time-select-container-content">
              <el-select
                v-model="startTime"
                :popper-append-to-body="false"
                placeholder="开始时间"
              >
                <el-option
                  v-for="(item, index) in durationArray"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="endTime"
                :popper-append-to-body="false"
                placeholder="结束时间"
              >
                <el-option
                  v-for="(item, index) in durationArray"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="input-item">
            <must-label text="借用天数"></must-label>
            <el-select
              v-model="selectedUseDuration"
              :popper-append-to-body="false"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in useDurationArray"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
    </panel-alert>
    <user-info-alert
      v-if="isShowUserInfoAlert"
      :uid="selectedPatientID"
      @onCloseClick="onUserInfoAlertCloseClick"
    ></user-info-alert>
  </div>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import MustLabel from "@c/common/alert/panel-alert/components/must-label.vue";

import { Select, Option } from "element-ui";

export default {
  components: {
    PanelAlert,
    MustLabel,
    elSelect: Select,
    elOption: Option,
    UserInfoAlert: () =>
      import("@c/common/alert/panel-alert/panel-alert-user-info.vue"),
  },

  props: {
    initSelectedDevice: Object,
    initSelectedPatient: Object,
  },

  data: function () {
    return {
      //选择设备
      deviceCurrentPage: 1,
      deviceTotalPage: undefined,
      deviceArray: [],
      selectedDeviceSN: undefined, //展示和提交需要
      //选择患者
      patientCurrentPage: 1,
      patientTotalPage: undefined,
      patientArray: [],
      selectedPatientName: undefined, //它处展示需要
      selectedPatientSleepID: undefined, //提交需要
      selectedPatientID: undefined,
      //监测时间
      startTime: "21:00",
      endTime: "06:00",
      //借用天数
      useDurationArray: ["1天", "2天", "3天", "7天"],
      selectedUseDuration: "1天",
      //用户信息弹窗
      isShowUserInfoAlert: false,
    };
  },

  directives: {
    loadmore: {
      inserted(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            parseInt(this.scrollHeight - this.scrollTop) <= this.clientHeight; //为了适配屏幕缩放
          if (condition) {
            binding.value(1);
          }
        });
      },
    },
  },

  computed: {
    durationArray() {
      let durationArray = [];
      for (const hour of [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ]) {
        for (const minute of ["00", "15", "30", "45"]) {
          durationArray.push(`${hour}:${minute}`);
        }
      }
      durationArray.push("24:00");
      return durationArray;
    },
    hospitalId() {
      return this.initSelectedPatient
        ? this.initSelectedPatient.model.hospitalId
        : this.$store.state.organizationModel?.id;
    },
  },

  created() {
    if (!this.initSelectedDevice) {
      this.getDevicePagingList();
    } else {
      this.selectedDeviceSN = this.initSelectedDevice.sn;
    }
    if (!this.initSelectedPatient) {
      this.getPatientPagingList();
    } else {
      this.selectedPatientSleepID = this.initSelectedPatient.model.suid;
      this.selectedPatientName = this.initSelectedPatient.name;
      this.selectedPatientID = this.initSelectedPatient.model.id;
    }
  },

  methods: {
    onDeviceLoadMore() {
      if (this.deviceCurrentPage == this.deviceTotalPage) {
        return;
      }
      this.deviceCurrentPage += 1;
      this.getDevicePagingList();
    },

    onPatientLoadMore() {
      if (this.patientCurrentPage == this.patientTotalPage) {
        return;
      }
      this.patientCurrentPage += 1;
      this.getPatientPagingList();
    },

    onCloseClick: function () {
      this.$emit("onCloseClick");
    },

    onConfirmClick: function () {
      if (
        this.selectedDeviceSN == undefined ||
        this.selectedPatientSleepID == undefined
      ) {
        this.$toast.showRed("请先选中设备和患者");
        return;
      }
      if (this.selectedPatientSleepID == 0) {
        this.$toast.showRed("当前用户没有关联到八戒睡眠用户，请联系管理员！");
        return;
      }
      const isValidDuration = this.verifyDuration();
      if (isValidDuration == false) {
        this.$toast.showRed("监测时段需大于4小时小于12小时！");
        return;
      }
      this.bindDevice();
    },

    onSelectedDevice(selectedDeviceSN) {
      this.selectedDeviceSN = selectedDeviceSN;
    },

    onSelectedPatient(selectedPatientName) {
      this.selectedPatientSleepID = this.patientArray.filter((item) => {
        return item.name == selectedPatientName;
      })[0].suid;
      this.selectedPatientID = this.patientArray.filter((item) => {
        return item.name == selectedPatientName;
      })[0].id;
    },

    //用户信息弹窗
    onCheckUserInfo() {
      if (this.selectedPatientSleepID == undefined) {
        this.$toast.showRed("请先选中患者");
        return;
      }
      this.isShowUserInfoAlert = true;
    },

    onUserInfoAlertCloseClick() {
      this.isShowUserInfoAlert = false;
    },

    // method
    async getDevicePagingList() {
      try {
        const params = {
          pageNum: this.deviceCurrentPage,
          hospitalId: this.hospitalId,
          pageSize: 10,
        };
        const data = await this.$api.getSleepDeviceList(params);
        this.deviceArray = this.deviceArray.concat(data.list);
        this.deviceTotalPage = data.pages;
        this.deviceCurrentPage = data.pageNum;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async getPatientPagingList() {
      try {
        const params = {
          pageNum: this.patientCurrentPage,
          hospitalId: this.hospitalId,
          pageSize: 10,
        };
        const data = await this.$api.getPatientList(params);
        this.patientArray = this.patientArray.concat(data.list);
        this.patientTotalPage = data.pages;
        this.patientCurrentPage = data.pageNum;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async bindDevice() {
      console.log(this.startTime, this.endTime);
      try {
        const frequency = this.selectedUseDuration.split("天")[0];
        const params = {
          sn: this.selectedDeviceSN,
          hospitalId: this.hospitalId,
          userId: this.selectedPatientSleepID,
          monitor: `${this.startTime}-${this.endTime}`,
          frequency,
        };
        const deviceID = await this.$api.bindUserAndSleepDevice(params);
        this.$emit("onBindSucceedClick", this.selectedPatientID, deviceID);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    verifyDuration() {
      const [selectedStartHour, selectedStartMinute] = this.startTime
        .split(":")
        .map((item) => {
          return parseInt(item);
        });
      const [selectedEndHour, selectedEndMinute] = this.endTime
        .split(":")
        .map((item) => {
          return parseInt(item);
        });
      try {
        if (selectedStartHour == selectedEndHour) {
          if (selectedEndMinute > selectedStartMinute) {
            throw "小于4小时";
          } else {
            throw "大于12小时";
          }
        }
        const trueEndHour =
          selectedEndHour > selectedStartHour
            ? selectedEndHour
            : selectedEndHour + 24;
        const duration = trueEndHour - selectedStartHour;
        if (duration <= 3) {
          throw "小于4小时";
        }
        if (duration >= 13) {
          throw "大于12小时";
        }
        if (duration == 4 && selectedEndMinute < selectedStartMinute) {
          throw "小于4小时";
        }
        if (duration == 12 && selectedEndMinute > selectedStartMinute) {
          throw "大于12小时";
        }
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bind-sleep-machine {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;

  &-row {
    display: flex;
    justify-content: space-between;

    &-1 {
      margin-top: 11px;
    }

    &-2 {
      margin-top: 17px;
    }

    &-check-container {
      position: relative;
      left: -60px;
      width: 220px;
      display: flex;
      align-items: center;

      &-text {
        color: #666666;
        font-size: 14px;
        font-weight: 300;
        width: 90px;
        box-sizing: border-box;
        white-space: nowrap;
      }

      &-check {
        position: absolute;
        right: 0;
        color: #2e6be6;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  &-time-select-container {
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &-content {
      width: 430px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.input-item {
  display: flex;
  align-items: center;

  &-name {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-disable {
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.25);
  }

  &-enable {
    font-size: 14px;
  }
}

//element-ui 多处出现
/deep/ .el-input__inner {
  width: 200px;
  height: 32px;
}

/deep/ .el-input__icon {
  line-height: 32px; //图片居中
}

/deep/ .el-select-dropdown__item {
  display: flex;
  align-items: center;

  span {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
